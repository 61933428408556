import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
  faCircle
} from '@fortawesome/free-solid-svg-icons'
import clsx from 'clsx'

// https://blog.logrocket.com/build-image-carousel-from-scratch-vanilla-javascript/
const Carousel = ({ cycle = 5000, children }) => {
  const [currentInView, setCurrentInView] = useState(0)
  useEffect(() => {
    const timeoutId = setTimeout(
      () => setCurrentInView(currentInView + 1),
      cycle
    )
    return () => clearTimeout(timeoutId)
  }, [currentInView])
  let i = 0
  const _currentInView = currentInView % React.Children.count(children)
  children = React.Children.map(children, (child) => {
    const element = React.cloneElement(child, {
      key: i,
      className: child.props.className + ' column is-full',
      style: {
        ...child.props.style,
        transition: 'transform 1s ease 0s',
        transform: `translateX(${_currentInView * 100}%)`
      }
    })
    i++
    return element
  })
  return (
    <>
      <div className='is-relative' style={{ overflowX: 'hidden' }}>
        <div
          className='is-absolute'
          style={{ width: '100%', height: '100%', zIndex: 1 }}
        >
          <div className='hero' style={{ height: '100%' }}>
            <div className='hero-body' style={{ height: '100%' }}>
              <div className='container' style={{ height: '100%' }}>
                <div
                  className='is-flex is-justify-content-space-between is-align-items-center is-hidden-touch'
                  style={{ height: '100%' }}
                >
                  <button
                    className='button is-white has-text-grey-lighter'
                    onClick={() => {
                      setCurrentInView(
                        (_currentInView > 0
                          ? _currentInView
                          : React.Children.count(children)) - 1
                      )
                    }}
                  >
                    <FontAwesomeIcon icon={faChevronRight} size='2x' />
                  </button>
                  <button
                    className='button is-white has-text-grey-lighter'
                    onClick={() => setCurrentInView(_currentInView + 1)}
                  >
                    <FontAwesomeIcon icon={faChevronLeft} size='2x' />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className='columns is-justify-content-flex-start is-mobile is-centered is-vcentered'
          style={{ marginTop: 0, marginBottom: 0 }}
        >
          {children}
        </div>
      </div>
      <div className='hero'>
        <div className='hero-body is-padding-top-0'>
          <div className='container'>
            <div className='is-flex is-justify-content-center is-align-items-center'>
              <div className='buttons has-addons are-small is-centered'>
                {React.Children.map(children, (_, index) => (
                  <button
                    key={index}
                    className={clsx('button is-white', {
                      'has-text-grey-lighter': _currentInView !== index,
                      'has-text-grey': _currentInView === index
                    })}
                    onClick={() => setCurrentInView(index)}
                  >
                    <FontAwesomeIcon icon={faCircle} size='2xs' />
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Carousel
